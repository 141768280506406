.App {
  width: 100%;
  overflow-x: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  padding-top: 70px; /* Adjust this value based on the header's height */
}

/* Optionally, set global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-button {
  position: relative;
}

.login-form {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 250px;
}

.login-form input {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-buttons {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}

.login-buttons button {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.login-buttons button:hover {
  background: #0056b3;
}

.login-buttons button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.profile-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
