.avatar-selector {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-width: 90vw;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.avatar-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 2px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #f8f9fa;
}

.avatar-option:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.avatar-option.selected {
  border-color: #007bff;
  background-color: #e7f1ff;
}

.avatar-option img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 0.75rem;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.avatar-option span {
  font-size: 0.9rem;
  text-align: center;
  color: #495057;
  font-weight: 500;
}

@media (max-width: 768px) {
  .avatar-selector {
    width: 95vw;
    padding: 1.5rem;
  }

  .avatar-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
    padding: 0.5rem;
  }

  .avatar-option img {
    width: 80px;
    height: 80px;
  }
} 