.profile-header {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.avatar-container {
  position: relative;
  flex-shrink: 0;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease;
}

.profile-avatar:hover {
  transform: scale(1.05);
}

.profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inline-input {
  font-size: 1.5rem;
  padding: 0.5rem;
  border: 2px solid transparent;
  border-radius: 4px;
  background: transparent;
  transition: all 0.2s ease;
  width: 100%;
  max-width: 400px;
}

.inline-input:focus {
  outline: none;
  border-color: #007bff;
  background: #f8f9fa;
}

.inline-input::placeholder {
  color: #999;
}

.email-display {
  font-size: 1.1rem;
  color: #666;
  margin: 0.5rem 0;
}

.save-button {
  align-self: flex-start;
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.save-button:hover {
  background: #0056b3;
  transform: translateY(-1px);
}

.save-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .inline-input {
    text-align: center;
  }

  .save-button {
    align-self: center;
  }
} 