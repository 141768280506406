/* src/pages/LeaderboardPage.css */

.leaderboard-page {
  padding: 20px;
  margin-top: 70px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.leaderboard-page h2 {
  text-align: center;
  margin-bottom: 30px;
}

.mode-filters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.leaderboard-table {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tr:hover {
  background-color: #f9f9f9;
}

/* Responsive design */
@media (max-width: 768px) {
  .leaderboard-page {
    padding: 10px;
  }
  
  th, td {
    padding: 8px 10px;
    font-size: 0.9rem;
  }
  
  .mode-filters {
    flex-wrap: wrap;
  }
} 