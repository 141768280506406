.achievements-section {
  margin-top: 2rem;
}

.achievements-section h2 {
  margin-bottom: 1rem;
  color: #333;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
} 