/* src/components/Buttons.css */

.button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  min-width: 120px;
}

/* Primary Button Style */
.button-primary {
  background-color: #4CAF50; /* A green color */
  color: white;
  border: none; /* No border */
}

.button-primary:hover {
  background-color: #45a049; /* Slightly darker green on hover */
  transform: translateY(-2px); /* Slight raise on hover */
}

/* Secondary Button Style */
.button-secondary {
  background-color: #f5f5f5; /* Same background as country cards */
  color: #333; /* Dark text color */
  border: 1px solid #ccc; /* Light gray border */
}

.button-secondary:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
  transform: translateY(-2px); /* Slight raise on hover */
}
