/* src/components/Footer.css */

.footer {
  background-color: #ffffff;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 40px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  height: 30px;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
  color: #666666;
  font-size: 0.9rem;
}
