/* src/pages/HomePage.css */
@import '../components/Buttons.css';

.homepage {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.section {
  position: relative;
  height: 50vh; /* Adjust height as needed */
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section .overlay {
  position: relative;
  z-index: 2;
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay color */
  z-index: 1;
}

.population-section {
  background-image: var(--population-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.area-section {
  background-image: var(--area-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-section {
  background-image: var(--coming-soon-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.population-modes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  margin: 0 auto;
}

.population-modes .button {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.population-modes .button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section h2 {
    font-size: 2rem;
  }
  .section p {
    font-size: 1.2rem;
  }
  .population-modes {
    max-width: 100%;
    padding: 0 1rem;
  }
}
