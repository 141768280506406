/* src/index.css */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: #ffffff;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
}

p {
  font-weight: 400;
  color: #555;
}
