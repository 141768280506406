.game-lobby {
  max-width: 600px;
  margin: 80px auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.game-lobby h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.lobby-info {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.lobby-info p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.lobby-info strong {
  color: #3498db;
  font-size: 1.4rem;
}

.players-list {
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.players-list h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.players-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.players-list li {
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.lobby-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.error {
  color: #e74c3c;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background: #fde8e8;
  border-radius: 4px;
}

.copy-button {
  position: relative;
  transition: all 0.3s ease;
}

.copy-button.copied {
  background-color: #4CAF50;
  color: white;
}

.copy-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.copy-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .game-lobby {
    margin: 60px 1rem;
    padding: 1rem;
  }

  .lobby-actions {
    flex-direction: column;
  }

  .button {
    width: 100%;
  }
} 