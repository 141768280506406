/* src/components/Header.css */

.header {
    background-color: #ffffff; /* Match with your body background for a cohesive look */
    padding: 10px 0; /* Removed horizontal padding */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Stay above other elements */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px; /* Center the content */
    margin: 0 auto;
    padding: 0 20px; /* Provide some horizontal padding */
  }
  
  .header-logo {
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline from link */
  }
  
  .logo-image {
    height: 40px; /* Adjust based on your logo's dimensions */
    margin-right: 10px;
  }
  
  .site-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000; /* Contrast against the header background */
    font-family: 'Roboto', sans-serif; /* Ensure consistency with your global font */
  }
  
  /* Navigation Links */
  .header-nav {
    display: flex;
    align-items: center;
    gap: 15px; /* Add consistent gap between nav items */
  }
  
  .nav-link {
    text-decoration: none;
    font-size: 1rem;
    color: #000000;
    transition: color 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .nav-link:hover {
    color: #dddddd; /* Slight color change on hover */
  }
  
  /* Mobile Menu Button */
  .menu-button {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
    color: #000000;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .site-name {
      font-size: 1.2rem;
    }
  
    .logo-image {
      height: 35px;
    }
  
    .menu-button {
      display: block;
    }
  
    .header-nav {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #ffffff;
      flex-direction: column;
      padding: 10px 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .header-nav.active {
      display: flex;
    }
  
    .nav-link {
      margin: 10px 0;
      font-size: 1.1rem;
      width: 100%;
      text-align: center;
      padding: 10px;
    }
  
    .nav-link:hover {
      background-color: #f5f5f5;
    }
  }
  
  @media (max-width: 480px) {
    .header-container {
      padding: 0 15px;
    }
  
    .site-name {
      font-size: 1.1rem;
    }
  
    .logo-image {
      height: 30px;
    }
  
    .nav-link {
      font-size: 1rem;
      padding: 8px;
    }
  }
  