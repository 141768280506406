/* src/pages/GameOverPage.css */

.game-over-page {
  text-align: center;
  padding: 20px;
  margin-top: 70px;
}

.game-over-page h2 {
  margin-bottom: 20px;
}

.game-over-page p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.incorrect-country, .correct-order {
  margin-top: 20px;
}

.country-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-over-buttons {
  margin-top: 30px;
}

.game-over-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

/* Score Submission Form Styles */
.score-submission {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.input-group input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.error {
  color: #dc3545;
  margin: 10px 0;
}

.submission-success {
  color: #28a745;
  margin: 20px 0;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .game-over-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .game-over-buttons button {
    margin: 5px 0;
  }

  .score-submission {
    margin: 20px;
    padding: 15px;
  }
}
