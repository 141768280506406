/* src/components/CountryCard.css */

.country-card {
  width: 100px;
  height: 140px;
  perspective: 1000px;
  margin: 5px;
  flex-shrink: 0; /* Prevent shrinking */
  touch-action: manipulation; /* Improve touch handling */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.country-card.clickable {
  cursor: pointer;
}

.country-card.hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.country-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front,
.card-back {
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-back {
  transform: rotateY(180deg);
}

.country-flag,
.country-outline {
  width: 100%;
  height: 60px;
  object-fit: cover;
}

.country-info {
  padding: 10px;
}

.country-name {
  font-size: 1rem;
  margin: 5px 0;
  word-wrap: break-word;
}

.country-detail {
  font-size: 0.9rem;
  margin: 3px 0;
  word-wrap: break-word;
}

/* Add styles for highlighted cards */
.card-face.highlighted-incorrect {
  background-color: #f8d7da; /* Light red background */
  border: 2px solid #f5c6cb; /* Light red border */
}

.card-face.highlighted-incorrect .country-name,
.card-face.highlighted-incorrect .country-detail {
  color: #721c24; /* Dark red text */
}

/* Correct Highlight Styles */
.card-face.highlighted-correct {
  background-color: #d4edda; /* Light green background */
  border: 2px solid #c3e6cb; /* Light green border */
}

.card-face.highlighted-correct .country-name,
.card-face.highlighted-correct .country-detail {
  color: #155724; /* Dark green text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .country-card {
    width: 90px;
    height: 120px;
    margin: 3px;
  }

  .country-flag,
  .country-outline {
    height: 50px;
  }

  .country-info {
    padding: 8px;
  }

  .country-name {
    font-size: 0.9rem;
    margin: 3px 0;
  }

  .country-detail {
    font-size: 0.8rem;
    margin: 2px 0;
  }
}

@media (max-width: 480px) {
  .country-card {
    width: 80px;
    height: 100px;
    margin: 2px;
  }

  .country-flag,
  .country-outline {
    height: 40px;
  }

  .country-info {
    padding: 6px;
  }

  .country-name {
    font-size: 0.8rem;
    margin: 2px 0;
  }

  .country-detail {
    font-size: 0.7rem;
    margin: 1px 0;
  }
}