.login-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
}

/* Style for the login/signup button when not logged in */
.login-button button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
  white-space: nowrap;
}

.login-button button:hover {
  background: #0056b3;
  transform: translateY(-1px);
}

.user-profile-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.user-profile-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.header-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #007bff;
}

.user-name {
  font-size: 0.9rem;
  color: #333;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logout-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #dc3545;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.logout-button:hover {
  background: #c82333;
}

.logout-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.login-form {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 250px;
}

.login-form input {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-buttons {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}

.login-buttons button {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.login-buttons button:hover {
  background: #0056b3;
}

.login-buttons button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .user-name {
    display: none;
  }

  .login-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 300px;
  }
} 