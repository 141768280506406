/* src/pages/GamePage.css */

.game-page {
  text-align: center;
  padding: 20px;
  margin-top: 70px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.sorted-countries-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sorted-countries {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  width: 100%;
}

.insert-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.insert-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 60px;
}

.insert-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.insert-button:active {
  transform: translateY(0);
}

.lower-button {
  background-color: #dc3545;
}

.here-button {
  background-color: #ffc107;
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
}

.higher-button {
  background-color: #28a745;
}

.instructions {
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.current-country {
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.current-country h3 {
  margin-bottom: 15px;
  color: #333;
}

.remaining-countries {
  margin-top: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.remaining-countries h3 {
  margin-bottom: 1rem;
  color: #2c3e50;
  text-align: center;
}

.remaining-countries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .game-page {
    padding: 10px;
    margin-top: 60px;
  }

  .sorted-countries {
    padding: 10px;
    min-height: 150px;
  }

  .insert-button {
    width: 50px;
    height: 50px;
    font-size: 1rem;
  }

  .instructions {
    font-size: 0.9rem;
    padding: 10px;
  }

  .remaining-countries-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.5rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .game-page {
    padding: 5px;
    margin-top: 50px;
  }

  .insert-button {
    width: 45px;
    height: 45px;
    font-size: 0.9rem;
  }

  .instructions {
    font-size: 0.8rem;
    padding: 8px;
  }
}
